@tailwind components;

@layer components {
  button.ui.button,
  button.ui.basic.button {
    @apply rounded-lg font-normal;
    font-family: "Cairo";
  }

  button.ui.button.primary {
    @apply bg-primary-900 hover:bg-primary-900 focus:bg-primary-900 active:bg-primary-900;
    font-family: "Cairo";
  }

  button.ui.basic.primary.button,
  button.ui.basic.primary.active.button,
  button.ui.basic.primary.buttons.active.button,
  button.ui.basic.primary.button:hover,
  button.ui.basic.primary.buttons.button:hover {
    @apply font-normal text-primary-900 ring-1 ring-primary-900 !important;
    font-family: "Cairo";
  }
}
