@tailwind components;

@layer components {
  .ui.pagination.menu .active.item {
    @apply rounded-lg border-none bg-primary-900 font-extrabold text-white shadow-none;
  }
  .ui.mini.menu {
    @apply border-none font-extrabold shadow-none;
  }
  .ui.menu .item:before {
    @apply border-none font-extrabold shadow-none;
  }
  .ui.menu:not(.vertical) .item {
    @apply border-none font-extrabold shadow-none;
  }
  .ui.menu .item {
    @apply static;
  }
  .ui.menu a.item:hover {
    @apply rounded-lg;
  }
}
