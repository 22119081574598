.ui.form .field.field input:-webkit-autofill:focus {
    @apply border-gray-300 bg-none bg-white shadow-none !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    font-family: 'Cairo';
  }

.ui.form .field.field input:-webkit-autofill {
    @apply border-gray-300 bg-none bg-white shadow-none !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
     font-family: 'Cairo';
}

.ui.form input[type="text"], .ui.form input[type="password"], .ui.form input[type="number"] {
  font-family: "Cairo";
}

/* .ui.form .field > .selection.dropdown {
 @apply min-h- 
} */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}
.ui.form input[type=number]{
  @apply rtl:text-right ltr:text-center;
}