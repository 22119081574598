@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  font-size: 14px;
} */

@layer components {
  .card {
    @apply relative mt-4 min-h-[33rem] rounded-xl bg-white 
 p-4 px-8 shadow-sm;
  }

  .table-header {
    @apply mb-2 grid h-10 w-full items-center rounded-xl bg-gray-100 
                            text-[#878787] hover:shadow-md ltr:pl-20 rtl:pr-20;
  }
  .table-btn {
    @apply flex w-full items-center rounded-sm px-2 py-2;
  }
}
